<template>
  <!-- v-if="$can('student.document.get')" -->
  <div class="pr-50 mb-2">
    <div class="d-flex align-items-center mb-75">
      <h3 class="mb-0 mr-50">File Center</h3>
    </div>
    <b-tabs>
      <b-tab title="Enrollment" class="mb-0" lazy>
        <student-document-list
          @focus="() => $emit('focus', null)"
          :focus-profile.sync="focusProfile"
          :student-data="studentData"
          category="enrollment"
        />
      </b-tab>
      <b-tab title="Financial" class="mb-0" lazy>
        <student-document-list
          @focus="() => $emit('focus', null)"
          :focus-profile.sync="focusProfile"
          :student-data="studentData"
          category="financial"
        />
      </b-tab>
      <b-tab title="Placement" class="mb-0" lazy>
        <student-document-list
          @focus="() => $emit('focus', null)"
          :focus-profile.sync="focusProfile"
          :student-data="studentData"
          category="placement"
        />
      </b-tab>
      <b-tab title="Transcript" class="mb-0" lazy>
        <student-document-list
          @focus="() => $emit('focus', null)"
          :focus-profile.sync="focusProfile"
          :student-data="studentData"
          category="transcript"
        />
      </b-tab>
      <b-tab title="Grades" class="mb-0" lazy>
        <student-document-list
          @focus="() => $emit('focus', null)"
          :focus-profile.sync="focusProfile"
          :student-data="studentData"
          category="grades"
        />
      </b-tab>
      <b-tab title="Misc" class="mb-0" lazy>
        <student-document-list
          @focus="() => $emit('focus', null)"
          :focus-profile.sync="focusProfile"
          :student-data="studentData"
          category="misc"
        />
      </b-tab>
      <b-tab title="Email" class="mb-0" lazy>
        <student-document-list
          @focus="() => $emit('focus', null)"
          :focus-profile.sync="focusProfile"
          :student-data="studentData"
          category="email"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BTab,
  BTabs,
  BRow,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormFile,
  BFormGroup,
} from 'bootstrap-vue';
import { barePermissionsData, initialAbility, permissionsData } from '@/libs/acl/config';
import { title } from '@core/utils/filter';

import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import StudentDocumentList from '@/views/apps/student/students-view/documents/StudentDocumentList';

export default {
  components: {
    StudentDocumentList,
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BTab,
    BTabs,
    BRow,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormInput,
    BFormFile,
    BFormGroup,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    studentData: {
      type: Object,
      required: true,
    },
    focusProfile: {
      type: String,
      required: false,
    },
  },
  data: () => {
    return {
      upload: {
        type: { value: undefined, label: 'Select Type' },
        file: undefined,
      },
      selectUploadEnrollmentOptions: [
        { value: 'select_value', label: 'Select Type' },
        { value: 'coe', label: 'Certificate of Eligibility' },
        { value: 'resume', label: 'Resume' },
      ],
    };
  },
  methods: {
    openUploadEnroll() {},
  },

  setup(props) {
    const applyAbility = (permissions, abilities) => {
      const modules = { ...permissions };

      for (const ability of abilities) {
        if (ability.subject === 'system') continue;
        if (!modules[ability.subject])
          modules[ability.subject] = {
            read: false,
            write: false,
            create: false,
            delete: false,
          };
        modules[ability.subject][ability.action] = true;
      }

      return Object.keys(modules).map((moduleName) => {
        return { module: moduleName, ...modules[moduleName] };
      });
    };

    const abilities = [...initialAbility];

    if (props.studentData.ability) {
      abilities.push(...props.studentData.ability);
    }

    const pmd = applyAbility({}, abilities);

    return {
      permissionsData: pmd,
      title,
    };
  },
};
</script>

<style></style>
