<template>
  <div class="container">
    <div no-body class="m-0 border-0">
      <div v-if="isLoading">
        <div class="simple-spinner">
          <span></span>
        </div>
      </div>

      <div class="d-flex flex-column" v-else>
        <span v-if="!documentData.length" class="text-left float-left mb-2 mt-75 text-muted">
          <feather-icon icon="AlertTriangleIcon" class="mr-25 text-warning" size="16" />
          This student has no associated {{ category }} documents.
        </span>

        <div class="enrollment-grid mb-1" v-else>
          <div class="d-flex flex-row card card-bg p-75 mb-0" v-for="(document, index) in documentData" :key="index">
            <div class="d-flex align-items-center justify-content-start w-100" @contextmenu.prevent="openContextMenu(index, $event)">
              <div class="td-img-holder">
                <b-link v-b-tooltip.hover.bottom="document.name" :alt="document.name">
                  <b-img
                    class="td-img-b-img"
                    :src="document.thumbnailUrl"
                    @click="previewDocument(document)"
                    @error="handleThumbnailError"
                  />
                </b-link>
              </div>
              <div class="d-flex flex-column h-100 w-100 justify-content-between">
                <div class="mb-50">
                  <div>
                    <div class="d-flex align-items-start justify-content-between">
                      <h6
                        class="mb-0 text-truncate-grid-item td-doc-doc-name font-weight-bolder"
                        :id="`student-document-${document.id}-title`"
                      >
                        {{ document.name }}
                      </h6>
                      <b-badge
                        v-if="document.references.rosterProfiles && document.references.rosterProfiles.length > 0"
                        variant="outline-info"
                      >
                        <feather-icon icon="LinkIcon" size="15" class="text-muted" />
                      </b-badge>
                      <b-tooltip :title="document.name" class="cursor-pointer" :target="`student-document-${document.id}-title`" />
                    </div>
                  </div>

                  <div class="font-small-2 mb-25" style="margin-top: -2px">
                    {{ momentTz(document.createdAt).format('MMMM Do YYYY @ hh:mm A') }}
                  </div>

                  <div>
                    <b-badge v-if="document.tfv && document.tfv.completed" variant="success" class="mr-75">P</b-badge>
                    <b-badge variant="dark">
                      {{ nameToExtension(document.name) }}
                    </b-badge>
                    &nbsp;
                    <small class="text-body">
                      <b-badge variant="info" size="sm" class="font-small-2">{{ document.subCategory }}</b-badge>
                    </small>
                  </div>
                </div>
                <div class="">
                  <b-button variant="primary" @click="previewDocument(document)">
                    <feather-icon icon="EyeIcon" class="mr-25" />
                    <span>Preview</span>
                  </b-button>
                </div>
              </div>
            </div>
          </div>

          <vue-context ref="studentDocumentMenu" style="min-width: 11rem">
            <li>
              <b-link class="d-flex align-items-center justify-content-between" @click="previewDocument(documentData[contextIndex], true)">
                <span>Download</span>
                <feather-icon icon="UploadIcon" size="15" class="ml-50 text-muted" />
              </b-link>
              <b-link
                v-if="$can('student.document.reference.update')"
                class="d-flex align-items-center justify-content-between"
                @click="openDocumentReference(documentData[contextIndex])"
              >
                <span>Reference</span>
                <feather-icon icon="LinkIcon" size="15" class="ml-50 text-muted" />
              </b-link>
              <b-link
                class="d-flex align-items-center justify-content-between"
                @click="updateDocument(documentData[contextIndex], 'archived')"
              >
                <span>{{ documentData[contextIndex] ? (documentData[contextIndex].archived ? 'Unarchive' : 'Archive') : 'Archive' }}</span>
                <feather-icon icon="ArchiveIcon" size="15" class="ml-50 text-muted" />
              </b-link>
              <b-link
                class="d-flex align-items-center justify-content-between"
                @click="updateDocument(documentData[contextIndex], 'hidden')"
              >
                <span>{{ documentData[contextIndex] ? (documentData[contextIndex].hidden ? 'Unhide' : 'Hide') : 'Hide' }}</span>
                <feather-icon icon="EyeOffIcon" size="15" class="ml-50 text-muted" />
              </b-link>
              <b-link class="d-flex align-items-center justify-content-between" @click="deleteDocument(documentData[contextIndex].id)">
                <span>Delete</span>
                <feather-icon icon="TrashIcon" size="15" class="ml-50 text-muted" />
              </b-link>
            </li>
          </vue-context>
        </div>

        <div class="card card-bg py-75 px-1">
          <div class="d-flex align-items-center justify-content-between">
            <p class="mb-0" v-if="Array.isArray(documentData)" style="padding-top: 6px">
              Showing {{ documentData.length }} {{ documentData.length === 1 ? 'item' : 'items' }} in {{ category }}.
            </p>

            <span class="d-flex align-items-center" style="gap: 1rem">
              <div class="d-flex align-items-center" style="gap: 1rem">
                <b-button
                  v-if="focusProfile"
                  variant="primary"
                  size="sm"
                  style="height: 2.65rem"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  @click="() => $emit('focus', null)"
                >
                  <feather-icon icon="XIcon" />
                  Unfocus
                </b-button>

                <b-button
                  variant="primary"
                  size="sm"
                  style="height: 2.65rem"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.modal-upload-enrollment
                >
                  <feather-icon icon="UploadIcon" />
                  Upload
                </b-button>

                <v-select
                  v-model="documentsFilter"
                  :options="['Hidden', 'Archived', 'Both']"
                  :placeholder="'Status'"
                  class="drop-shadow-default"
                  style="min-width: 11rem"
                />
              </div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalResults"
                :per-page="9"
                class="mb-0"
                first-number
                last-number
              ></b-pagination>
            </span>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-select-reference"
      ref="modal-select-reference"
      title="Select Profile"
      cancel-variant="outline-secondary"
      footer-class="m-0 p-0"
      body-class="pt-0 pb-0"
      no-stacking
      centered
    >
      <div>
        <div
          v-for="profile in referenceProfiles"
          class="border rounded p-75 pl-1 d-flex flex-row align-items-center mb-75 cursor-pointer"
          @click="onProfileSelect($event, profile.value)"
        >
          <feather-icon icon="FileTextIcon" size="16" class="mr-25" />
          <span class="text-weight-bold" style="margin-left: 2px">{{ profile.label }}</span>
        </div>
      </div>

      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>

    <b-modal
      id="modal-upload-enrollment"
      ref="modal-upload-enrollment"
      title="Select Document Type"
      cancel-variant="outline-secondary"
      footer-class="m-0 p-0"
      body-class="pt-0 pb-0"
      no-stacking
      centered
    >
      <div>
        <div
          v-for="documentType in selectUploadEnrollmentOptions[category]"
          class="border rounded p-75 pl-1 d-flex flex-row align-items-center mb-75 cursor-pointer"
          @click="onSubCategorySelect($event, documentType.value)"
        >
          <feather-icon icon="FileTextIcon" size="16" class="mr-25" />
          <span class="text-weight-bold" style="margin-left: 2px">{{ documentType.label }}</span>
        </div>
      </div>

      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>

    <b-modal
      id="modal-upload-document"
      ref="modal-upload-document"
      title="Upload Document"
      cancel-variant="outline-secondary"
      centered
      body-class="p-0 m-0"
    >
      <div>
        <div v-if="fileUploading" class="pr-2 pl-2 mb-75">
          <b-progress :value="100" :max="100" animated></b-progress>
        </div>

        <div class="pl-2 pr-2 pt-0 pb-0">
          <b-form class="">
            <b-form-group label="Click Here to Upload Document" label-for="select-upload-document">
              <b-form-file
                id="select-upload-document-file"
                :accept="allowedExtensions"
                :capture="true"
                :autofocus="true"
                @input="onFileSelect"
                v-model="upload.file"
              />
            </b-form-group>

            <b-form-group v-if="upload.file" label="Document Name" label-for="select-upload-name">
              <b-form-input id="select-upload-name" v-model="upload.fileName" :placeholder="`${title(upload.type.label)} #1.pdf`">
              </b-form-input>
            </b-form-group>
          </b-form>

          <div class="d-flex justify-content-end">
            <b-button variant="primary" size="sm" v-if="upload.file" @click="openUploadEnroll" :disabled="fileUploading">
              <feather-icon icon="UploadIcon" size="12" />
              <span class="ml-25 align-middle">Upload File</span>
            </b-button>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>

    <b-modal :size="modalData.size" modal-class="container" body-class="m-0 p-0" hide-footer hide-header centered v-model="modalData.show">
      <div>
        <!-- TOP BAR -->
        <!-- <h1>{{ modalData.title }}</h1> -->
        <!-- <h5>Document Preview</h5> -->
        <div class="d-flex align-items-center justify-content-between px-75 py-1">
          <div class="d-flex align-items-center">
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="dark" size="md" @click="expandPreviewModal">
              <feather-icon :icon="modalData.expandIcon" />
            </b-button>
            &nbsp;
            <span v-b-tooltip.hover.bottom="modalData.document.name">
              {{ modalData.document.name }}
            </span>
          </div>

          <b-button
            v-if="canModifyPrevEdu(modalData.document)"
            variant="primary"
            size="sm"
            @click="openEnrollmentPrevEdu(modalData.document)"
          >
            <feather-icon icon="EditIcon" size="12" />
            <span class="ml-25 align-middle">Edit RoPE</span>
          </b-button>

          <b-button variant="primary" size="sm" @click="downloadFile(modalData.document.src)">
            <feather-icon icon="DownloadIcon" size="12" />
            <span class="ml-25 align-middle">Download File</span>
          </b-button>
        </div>

        <div class="col-12">
          <div class="audio-holder" v-show="modalData.document.tfv.proofUrl">
            <audio :src="modalData.document.tfv.proofUrl" controls class="w-100"></audio>
          </div>
        </div>

        <!-- <hr /> -->

        <viewer
          v-if="modalData.document.imgSrc"
          ref="viewer"
          :options="modalData.imgViewer.options"
          :images="modalData.imgViewer.images"
          class="viewer mt-5 mb-5"
          style="margin-top: 200px"
          @inited="vViewerInitialized"
        >
          <template #default="scope">
            <figure class="images">
              <div v-for="{ source, thumbnail, alt } in scope.images" :key="source" class="image-wrapper">
                <img class="image" :src="thumbnail" :data-source="source" :alt="alt" />
              </div>
            </figure>
          </template>
        </viewer>

        <div v-else>
          <iframe :src="modalData.document.src" class="w-100 doc-preview"></iframe>
        </div>
      </div>

      <template #modal-header>
        <div></div>
      </template>

      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BButtonGroup,
  BCard,
  BCardBody,
  BCardText,
  BDropdown,
  BDropdownDivider,
  BDropdownItem,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BImg,
  BLink,
  BModal,
  BPagination,
  BProgress,
  BRow,
  BTooltip,
  VBModal,
  VBTooltipPlugin,
} from 'bootstrap-vue';
import { title } from '@/@core/utils/filter';
import store from '@/store';
import VueContext from 'vue-context';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import { ref, watch } from '@vue/composition-api';
import router from '@/router';
import fileDownload from 'js-file-download';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { component as Viewer } from 'v-viewer';
import acceptedMimeTypes from '@/libs/data/mime-types';
import { momentTz } from '@/libs/utils/time';

export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
    BImg,
    BLink,
    BButton,
    BRow,
    vSelect,
    Ripple,
    BModal,
    VBModal,
    BForm,
    BFormInput,
    BFormFile,
    BFormGroup,
    BBadge,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BProgress,
    BPagination,
    BTooltip,
    VueContext,
    // 3rd Party
    Viewer,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltipPlugin,
    Ripple,
  },
  props: {
    studentData: {
      type: Object,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
    focusProfile: {
      type: String,
    },
  },
  computed: {
    allowedExtensions() {
      const cat = this.selectUploadEnrollmentOptions[this.category].find((opt) => opt.value === this.upload.type);
      if (!cat) return '.jpg, .jpeg, .png, .pdf, .doc';
      if (!cat.extensions) return '*';
      return cat.extensions.join(', ');
    },
  },
  data: () => {
    return {
      contextIndex: undefined,
      fileUploading: false,
      upload: {
        type: undefined,
        fileName: undefined,
        file: undefined,
      },

      disableDownload: false,
      defaultThumbnail: 'https://ip-management.us-east-1.linodeobjects.com/thumbnails/generic.png',
      selectUploadEnrollmentOptions: {
        enrollment: [
          { value: 'coe', label: 'Certificate of Eligibility' },
          { value: 'tac', label: 'Terms and Conditions' },
          { value: 'ef', label: 'Enrollment Form' },
          { value: 'resume', label: 'Resume' },
        ],
        financial: [
          { value: 'invoice', label: 'Invoice' },
          { value: 'receipt', label: 'Receipt' },
          { value: 'quote', label: 'Quote' },
          { value: 'ital', label: 'Initial Tuition Award Letter' },
          { value: 'gtal', label: 'Graduation Tuition Award Letter' },
          { value: 'tdl', label: 'Tuition Denial Letter' },
        ],
        placement: [
          { value: 'resume', label: 'Resume' },
          { value: 'me', label: 'Meaningful Employment' },
          { value: 'jol', label: 'Job Offer Letter' },
          { value: 'coc', label: 'Certificate of Completion' },
          { value: 'esr', label: 'Exam Score Report' },
          { value: 'meal', label: 'ME Award Letter' },
          { value: 'medl', label: 'ME Denial Letter' },
        ],
        transcript: [
          // { value: 'transcript', label: 'Transcript', disabled: true },
          { value: 'prior', label: 'Prior Transcript' },
          { value: 'post', label: '(Unofficial) Post Transcript' },
          { value: 'post_official', label: '(Official) Post Transcript' },
          { value: 'toc', label: 'Transfer of Credits' },
          { value: 'rpet', label: 'Record of Previous Education' },
          { value: 'certificate', label: 'Certificate' },
        ],
        grades: [{ value: 'report', label: 'Grade Report' }],
        misc: [
          { value: 'misc', label: 'Misc' },
          { value: 'evidence', label: 'Evidence' },
        ],
        email: [
          { value: 'raw', label: 'RAW (.eml)', extensions: ['.eml'] },
          // { value: 'rendered', label: 'Rendered' },
        ],
      },
      modalData: {
        audioUrl: null,
        title: "<i class='feather icon-eye'></i> Document Preview - %fullName - %id",
        document: {
          category: null,
          id: null,
          name: null,
          tfv: {
            proofUrl: null,
          },
          src: '#',
          imgSrc: '#',
          downloadSrc: null,
        },
        imgViewer: {
          options: {
            inline: true,
            button: false,
            navbar: false,
            title: false,
            toolbar: false,
            tooltip: true,
            movable: true,
            zoomable: true,
            rotatable: true,
            scalable: true,
            transition: true,
            fullscreen: true,
            keyboard: true,
            url: 'data-source',
          },
          images: [],
        },
        size: 'lg',
        show: false,
        expandIcon: 'Maximize2Icon',
      },
      referenceProfiles: [],
      referenceDocument: null,
    };
  },
  methods: {
    vViewerInitialized(viewer) {
      this.$viewer = viewer;
    },
    handleThumbnailError(event) {
      event.target.src = this.defaultThumbnail;
      event.target.onerror = null;
    },
    nameToExtension(name) {
      if (!name) return name;
      const spl = name.split('.');
      return spl[spl.length - 1];
    },
    openContextMenu(index, event) {
      this.contextIndex = index;
      this.$refs.studentDocumentMenu.open(event, { item: this.documentData[index] });
      event.preventDefault();
    },
    async deleteDocument(documentId) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure you want to delete this document?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Remove',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          const documentResp = await store
            .dispatch('studentStoreModule/deleteStudentDocument', {
              studentId: this.studentData.id,
              documentId,
            })
            .then(() => this.refetchData())
            .catch((error) => console.log('Failed to delete document', error));
        }
      });
    },
    async updateDocument(document, status) {
      const payload = {};

      if (status === 'archived') payload.archived = !document.archived;
      if (status === 'hidden') payload.hidden = !document.hidden;

      await store
        .dispatch('studentStoreModule/updateStudentDocument', {
          studentId: this.studentData.id,
          documentId: document.id,
          documentData: payload,
        })
        .then((response) => {
          this.refetchData();
        })
        .catch((error) => {
          console.log('Failed to update student document', error);

          if (error.response) {
            const { response } = error;
            return this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failed to update document',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: response.data.message || 'An unknown error has occurred.',
              },
            });
          }

          return this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to update document',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          });
        });
    },
    async onSubCategorySelect($event, value) {
      $event.preventDefault();
      this.upload = {
        type: value,
        fileName: undefined,
        file: undefined,
      };
      this.$refs['modal-upload-document'].show();
    },

    async openUploadEnroll() {
      if (this.fileUploading) {
        return;
      }
      if (!this.upload.type) {
        return this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed to create document.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Failed to select or invalid subcategory.',
          },
        });
      }

      this.fileUploading = true;
      const formData = new FormData();
      formData.append('document', this.upload.file);

      try {
        const documentResp = await store
          .dispatch('studentStoreModule/createStudentDocument', {
            studentId: this.studentData.id,
            payload: {
              name: this.upload.fileName || this.upload.file.name,
              category: this.category,
              subCategory: this.upload.type,
            },
          })
          .catch((error) => console.log('Failed to create document', error));

        if (documentResp.status !== 201) {
          this.fileUploading = false;
          return this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create document.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: documentResp.data.message || 'An unknown error has occurred.',
            },
          });
        }

        const document = documentResp.data;

        const uploadResp = await store
          .dispatch('studentStoreModule/uploadStudentDocument', {
            studentId: this.studentData.id,
            documentId: document.id,
            formData,
          })
          .catch((error) => console.log('Failed to upload student document', error));

        if (uploadResp.status !== 200) {
          this.fileUploading = false;
          return this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to upload document.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: uploadResp.data.message || 'An unknown error has occurred.',
            },
          });
        }

        this.upload = {
          type: null,
          fileName: undefined,
          file: undefined,
        };

        this.fileUploading = false;
        this.$refs['modal-upload-document'].hide();
        this.refetchData();

        return this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Document uploaded successfully.',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        });
      } catch (e) {
        if (e.response) {
          const { response } = e;

          this.fileUploading = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create and upload document.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: response.data.message || 'An unknown error has occurred.',
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create and upload document.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: e.message,
            },
          });
        }
      }
    },

    onFileSelect() {
      console.log(this.upload.file);

      if (!this.upload.fileName) {
        this.upload.fileName = this.upload.file.name;
      }
    },
    documentHasProof(document) {
      return document.tfv && document.tfv.proofUrl;
    },
    async downloadProof(document) {
      if (document.tfv && document.tfv.proofUrl) {
        window.open(document.tfv.proofUrl, '_blank');
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed to download',
            icon: 'SettingsIcon',
            variant: 'danger',
            text: 'Document does not have a recording attached',
          },
        });
      }
    },
    async previewDocument(document, downloadOnly = false) {
      if (this.disableDownload) return;
      console.log(document);
      if (!downloadOnly && document.category === 'email' && document.subCategory === 'raw') {
        downloadOnly = true;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Coming Soon',
            text: "We're working on this feature, in the meantime, please download and open in your local email client.",
            variant: 'danger',
          },
        });
      }

      this.disableDownload = true;

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Please wait a moment...',
          icon: 'SettingsIcon',
          variant: 'success',
          text: "We're preparing the document.",
        },
      });

      await store
        .dispatch('studentStoreModule/documentStudentDocumentPreview', {
          studentId: this.studentData.id,
          documentId: document.id,
        })
        .then((response) => {
          const { url, mime } = response.data;
          const modalData = this.modalData;

          modalData.document = document;

          if (document.tfv) {
            modalData.audioUrl = document.tfv.proofUrl;
          }

          modalData.title = modalData.title.replace('%fullName', this.studentData.fullName).replace('%id', this.studentData.studentId);

          const contentType = mime ? mime : 'application/octet-stream';
          modalData.document.downloadSrc = url;

          if (contentType === acceptedMimeTypes.pdf) {
            modalData.document.src = `${url}#toolbar=0`;
          } else if (acceptedMimeTypes.docs.includes(contentType)) {
            modalData.document.src = `https://officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`;
          } else if (mime.match(acceptedMimeTypes.image)) {
            modalData.document.imgSrc = url;
            modalData.imgViewer.images = [
              {
                thumbnail: this.modalData.document.imgSrc,
                source: this.modalData.document.imgSrc,
                alt: this.modalData.document.name,
              },
            ];
          } else {
            return this.downloadFile();
          }

          if (!downloadOnly) {
            modalData.show = true;
          } else {
            this.downloadFile();
          }

          this.modalData = modalData;

          this.disableDownload = false;
        })
        .catch((error) => {
          console.log('Failed to download student document', error);
          this.disableDownload = false;

          if (error.response) {
            const { response } = error;
            return this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failed to download file.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: response.data.message || 'An unknown error has occurred.',
              },
            });
          }

          return this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to download file.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          });
        });
    },
    expandPreviewModal() {
      this.modalData.size = this.modalData.size === 'xl' ? 'lg' : 'xl';
      this.modalData.expandIcon = this.modalData.expandIcon === 'Maximize2Icon' ? 'Minimize2Icon' : 'Maximize2Icon';
    },
    openEnrollmentPrevEdu(document) {
      window.open(`https://enroll.intellectualpoint.com/enrolls/${document.metadata.enrollmentId}/credit?email=${this.studentData.email}`);
    },
    canModifyPrevEdu(document) {
      console.log('can modify prev edu', document, document && document.metadata && document.metadata.enrollmentId);
      return (
        document &&
        document.category === 'transcript' &&
        document.subCategory === 'rpet' &&
        document.metadata &&
        document.metadata.enrollmentId
      );
    },
    downloadFile(url = this.modalData.document.downloadSrc) {
      console.log('downloading file', url);
      if (!url) return;

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Please wait a moment...',
          icon: 'DownloadIcon',
          variant: 'success',
          text: 'Your download till start shortly...',
        },
      });

      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          fileDownload(blob, this.modalData.document.name);
        })
        .catch((err) => {
          // sweetalert here
          console.error('Failed to download file:', err);
        });
    },

    async openDocumentReference(document) {
      this.referenceDocument = document;
      await this.retrieveStudentProfiles().catch((error) => console.error('failed to open reference docs', error));
      if (this.referenceProfiles.length > 0) this.$refs['modal-select-reference'].show();
    },
    async retrieveStudentProfiles() {
      try {
        const response = await this.$store.dispatch('rosterStoreModule/fetchProfiles', {
          page: 1,
          limit: 100,
          student: this.studentData.id,
          populate: 'session',
        });
        const results = response.data.results;

        if (results.length === 0) {
          return;
        }

        this.referenceProfiles = results.map((profile) => {
          const cohortDisplay = profile.session
            ? `${momentTz(profile.session.startDate).format('MMM Do YYYY')} ${profile.session.name}`
            : 'Unassign';
          const createdAtRendered = momentTz(profile.createdAt).format('MMM Do YYYY');
          return {
            label: `${cohortDisplay} - ${createdAtRendered} - ${title(profile.status)}`,
            value: profile.id,
          };
        });
      } catch (error) {
        console.error('failed to do profile retrieval', error);
        return [];
      }
    },
    async onProfileSelect($event, value) {
      $event.preventDefault();

      console.log('> should link profile to document:', value);

      store
        .dispatch('studentStoreModule/addStudentDocumentReference', {
          studentId: this.studentData.id,
          documentId: this.referenceDocument.id,
          referenceData: { profileId: value },
        })
        .then(() => {
          console.log('> successfully referenced student document');
          this.$refs['modal-select-reference'].hide();
          return this.refetchData();
        })
        .catch((error) => {
          console.error('> failed to reference student document');
        });

      // todo: send operation
    },
  },
  watch: {
    focusProfile() {
      this.refetchData();
    },
  },
  setup(props) {
    const isLoading = ref(false);
    const documentData = ref(null);
    const documentsFilter = ref('');
    const documentCategory = ref(props.category);

    const totalResults = ref(0);
    const totalPages = ref(0);
    const currentPage = ref(1);

    const refetchData = () => {
      if (isLoading.value) return;
      isLoading.value = true;

      const queryParams = {
        category: props.category,
        limit: 9,
        page: currentPage.value,
        sortBy: 'createdAt:desc',
      };

      if (documentsFilter.value === 'Both') {
        queryParams.hidden = true;
        queryParams.archived = true;
      } else if (documentsFilter.value === 'Hidden') {
        queryParams.hidden = true;
      } else if (documentsFilter.value === 'Archived') {
        queryParams.archived = true;
      }

      if (props.focusProfile) {
        queryParams['references_rosterProfile'] = props.focusProfile;
      }

      store
        .dispatch('studentStoreModule/fetchStudentDocuments', {
          id: router.currentRoute.params.id,
          queryParams,
        })
        .then((response) => {
          totalResults.value = response.data.totalResults;
          totalPages.value = response.data.totalPages;
          documentData.value = response.data.results;
          isLoading.value = false;
          console.log('documents', response.data);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            documentData.value = undefined;
            documentsFilter.value = '';
            isLoading.value = false;
          }
        });
    };

    refetchData();

    watch([currentPage, documentsFilter], () => {
      refetchData();
    });

    return {
      isLoading,
      documentData,
      documentCategory,
      documentsFilter,
      totalResults,
      totalPages,
      currentPage,
      momentTz,
      title,
      refetchData,
    };
  },
};
</script>

<style lang="scss">
.container {
  .td-img-holder {
    //flex-basis: 30%;
    max-width: 90px;
    min-width: 90px;
    margin-right: 10px;
  }

  .td-img-b-img {
    border-radius: 0.4rem;
    width: 100%;
  }

  .td-doc-holder {
    vertical-align: top;
  }

  .text-truncate-grid-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 8vw;
  }

  .file-container {
    column-gap: 1rem;
    padding: 1rem;
    border-radius: 0.6rem;
    border: 1px solid #292b38;
  }

  .file-info {
    display: flex;
    //flex-basis: 75%;
  }

  .audio-holder audio {
    height: 50px;
    border: 1px solid #292b38;
    border-radius: 2rem;
  }

  iframe.doc-preview {
    height: 600px;
    overflow: scroll;
    border: none;
  }

  .audio-holder audio::-webkit-media-controls-panel {
    background-color: #1a2029;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .audio-holder audio::-webkit-media-controls-mute-button {
    background-color: #0059ff;
    border-radius: 100%;
  }

  .audio-holder audio::-webkit-media-controls-play-button {
    background-color: #0059ff;
    border-radius: 100%;
    color: white;
  }

  .audio-holder audio::-webkit-media-controls-timeline-container {
    background-color: #0059ff;
  }

  .audio-holder audio::-webkit-media-controls-current-time-display {
    color: #fff;
  }

  .audio-holder audio::-webkit-media-controls-time-remaining-display {
    color: #fff;
  }

  .audio-holder audio::-webkit-media-controls-timeline {
    background-color: #0059ff;
    border-radius: 25px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .audio-holder audio::-webkit-media-controls-volume-slider {
    background-color: #0059ff;
    border-radius: 25px;
    padding-left: 8px;
    padding-right: 8px;
    margin-right: 10px;
  }

  .viewer-wrapper {
    position: relative;
    background: #333;
    height: 100%;
  }

  .methods {
    margin-bottom: 1em;
    flex-wrap: wrap;

    & > * {
      margin-right: 0.75rem;
    }
  }

  .options-panel {
    .panel-block {
      padding: 0;

      .checkbox {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0.5em 0.75em;
      }
    }
  }

  .viewer {
    height: 100%;

    .images {
      visibility: hidden;
      height: 100%;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding: 5px;

      .image-wrapper {
        display: inline-block;
        width: calc(33% - 20px);
        margin: 5px 5px 0 5px;

        .image {
          width: 100%;
          cursor: pointer;
          display: inline-block;
        }
      }
    }
  }

  .viewer-container {
    margin-top: 55px;
    margin-bottom: 100px;
  }

  .enrollment-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    //margin-top: 1rem;
  }

  @media (max-width: 750px) {
    .enrollment-grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @media (min-width: 1750px) {
    .enrollment-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
</style>
